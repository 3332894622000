









































import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { Component, Vue } from 'vue-property-decorator';

import { IStateOfConfirmEmail } from '@/services/auth-store.service';
import { StatusOfConfirmEmail } from '@/services/api/auth-api.service';

import WbGrowl from '@/components/ui-elements/wb-growl/wb-growl.vue';
import WbAuthContainer from '../wb-auth-container/wb-auth-container.vue';

interface ISubscriptions {
	stateOfConfirmEmail: Subscription | null;
}

@Component({
	name : 'wb-confirm-email-address',
	components : {
		WbGrowl,
		WbAuthContainer,
	},
})
export default class WbConfirmEmailAddress extends Vue {
	public statusOfConfirmEmail = StatusOfConfirmEmail;

	private subscriptions: ISubscriptions = {
		stateOfConfirmEmail : null,
	}

	public modal = {
		status : '',
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbConfirmEmailAddress ', 'background: blue; color: #FFF');

		this.subscribeToStore();
		this.$auth.confirmEmailAddress(this.$route.params.token);
		this.isBusy = true;
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbConfirmEmailAddress ', 'background: purple; color: #FFF');

		this.unsubscribeFromStore();
	}

	public goToLogin(): void {
		this.$router.push({ name : 'wbLogin', });
	}

	private subscribeToStore() {
		this.subscriptions.stateOfConfirmEmail = this.$auth.stateOfConfirmEmailAddress$
			.pipe(
				skipWhile((stateOfConfirmEmail) => stateOfConfirmEmail === null),
				tap((stateOfConfirmEmail: IStateOfConfirmEmail | null) => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					this.modal.status = stateOfConfirmEmail!.status; // Can't be null because of skipWhile!

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore() {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
