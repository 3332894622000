









































































import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { Component, Vue } from 'vue-property-decorator';
import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';
import WbGrowl from '@/components/ui-elements/wb-growl/wb-growl.vue';
import WbAuthContainer from '../wb-auth-container/wb-auth-container.vue';

interface ISubscriptions {
	loggedInSuccess: Subscription | null;
}

@Component({
	name : 'wb-login',
	components : {
		WbGrowl,
		WbAuthContainer,
	},
})
export default class WbLogin extends Vue {
	private subscriptions: ISubscriptions = {
		loggedInSuccess : null,
	}

	public loginModel: IModel = {
		fields : {
			email : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.email,
				placeholder : 'Email address',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Email address is required.',
					email : 'This is not an email address',
				},
			},
			password : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.password,
				placeholder : 'Password',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Password is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	public growl = {
		visible : false,
		status : '',
		txt : '',
	}

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbLogin ', 'background: blue; color: #FFF');

		this.subscribeToStore();
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbLogin ', 'background: purple; color: #FFF');

		this.unsubscribeFromStore();
	}

	public login(): void {
		const formValidation = this.$formValidation;

		const validation = formValidation.validate(this.loginModel);

		if (validation.ok()) {
			// Get token
			this.$googleCaptcha.getToken()
				.pipe(tap((token: string) => {
					if (token === '') {
						this.showErrorOnGrowl('Please reload the page.');
					} else {
						validation.result.captchaToken = token;

						this.$auth.login(validation.result);
						this.isBusy = true;
					}
				})).subscribe();
		}
	}

	public goToRegistration(): void {
		this.$router.push({ name : 'wbRegistration', });
	}

	public goToForgottenPassword(): void {
		this.$router.push({ name : 'wbForgottenPassword', });
	}

	private showErrorOnGrowl(txt: string): void {
		this.growl.visible = true;
		this.growl.status = 'danger';
		this.growl.txt = txt;
	}

	private subscribeToStore(): void {
		this.$auth.resetIsLoggedInSuccess(); // !!! order

		this.subscriptions.loggedInSuccess = this.$auth.isLoggedInSuccess$
			.pipe(
				skipWhile((loginPayload) => loginPayload === null),
				tap((loginPayload: boolean | null) => {
					if (loginPayload) {
						this.$router.push({ name : 'wbSite', });
					} else {
						this.showErrorOnGrowl('Your username/password is not correct.');
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore() {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
