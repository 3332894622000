














































































































import { Component, Vue } from 'vue-property-decorator';
import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';

import WbAuthContainer from '../wb-auth-container/wb-auth-container.vue';
import { ResponseMessage } from '@/services/interfaces/message.interface';

interface ISubscriptions {
	registrationStatus: Subscription | null;
}

@Component({
	name : 'wb-registration',
	components : {
		WbAuthContainer,
	},
})
export default class WbRegistration extends Vue {
	private subscriptions: ISubscriptions = {
		registrationStatus : null,
	}

	public registrationModel: IModel = {
		fields : {
			organization : {
				oldValue : '',
				value : '',
				placeholder : 'Company Name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Company name is required.',
				},
			},
			firstname : {
				oldValue : '',
				value : '',
				placeholder : 'First name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'First name is required.',
				},
			},
			lastname : {
				oldValue : '',
				value : '',
				placeholder : 'Last name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Last name is required.',
				},
			},
			email : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.email,
				placeholder : 'Email address',
				required : true,
				status : {},
				focus : false,
				error : {
					email : 'This is not a valid email address.',
					required : 'Email address is required.',
				},
			},
			password : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.password,
				placeholder : 'Password',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Password is required.',
				},
			},
			passwordAgain : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.password,
				placeholder : 'Password',
				required : true,
				status : {},
				focus : false,
				customValidation : (val, model) => {
					if (val === model.fields.password.value) {
						return true;
					} else {
						return { txt : 'Your password and confirmation password do not match.', };
					}
				},
				error : {
					required : 'Password again is required.',
				},
			},
			acceptTermsAndConditions : {
				oldValue : false,
				value : false,
				placeholder : 'Terms ad Conditions',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'You need to accept the Terms of Conditions',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public isBusy = false;

	public growl = {
		visible : false,
		status : '',
		txt : '',
	}

	public lang: Pick<ResponseMessage, 'password_too_short' | 'password_weak' | 'email_already_in_use'> = {
		password_too_short : 'Your new password must be at least 8 characters long.',
		password_weak : 'Your password is too weak: It should contains at least 2 uppercase letters, 2 digits and 2 special characters',
		email_already_in_use : 'The email address you have entered is already registered. If you forget your password, you can request to reset it.',
	}

	public registrationWasSuccessful = false;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbRegistration ', 'background: blue; color: #FFF');

		// IN DEV ONLY
		if (process.env.NODE_ENV !== 'production') {
			this.registrationModalWithTestData('' + process.env.VUE_APP_FILL_REGISTRATION_MODAL_DATA);
		}

		this.resetStateAndSubscribeToStore();
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbRegistration ', 'background: purple; color: #FFF');

		this.unsubscribeFromStore();
	}

	public canSend(): boolean {
		return (
			this.registrationModel.fields.organization.value !== '' &&
			this.registrationModel.fields.lastname.value !== '' &&
			this.registrationModel.fields.firstname.value !== '' &&
			this.registrationModel.fields.email.value !== '' &&
			this.registrationModel.fields.password.value !== '' &&
			this.registrationModel.fields.passwordAgain.value !== '' &&
			this.registrationModel.fields.acceptTermsAndConditions.value === true
		);
	}

	public register(): void {
		if (this.canSend()) {
			const formValidation = this.$formValidation;

			const validation = formValidation.validate(this.registrationModel);

			if (validation.ok()) {
				// Get token
				this.$googleCaptcha.getToken()
					.pipe(tap((token: string) => {
						if (token === '') {
							this.showErrorOnGrowl('Please try register again.');
						} else {
							validation.result.captchaToken = token;

							// Register
							this.$auth.register(validation.result);
							this.isBusy = true;
						}
					})).subscribe();
			}
		}
	}

	public goToLogin(): void {
		this.$router.push({ name : 'wbLogin', });
	}

	private registrationModalWithTestData(data: string): void {
		const parsedData = JSON.parse(data);
		Object.keys(parsedData).forEach((key) => {
			this.registrationModel.fields[key].value = parsedData[key];
		});
	}

	private showErrorOnGrowl(txt: string): void {
		this.growl.visible = true;
		this.growl.status = 'danger';
		this.growl.txt = txt;
	}

	private emptyGrowl(): void {
		this.growl.visible = false;
		this.growl.status = '';
		this.growl.txt = '';
	}

	private resetStateAndSubscribeToStore() {
		this.$auth.resetRegistrationState(); // !!! order

		this.subscriptions.registrationStatus = this.$auth.registrationState$
			.pipe(
				skipWhile((registerPayload) => registerPayload === null),
				tap((registerPayload: {action: 'password_too_short' | 'password_weak' | 'email_already_in_use' | 'success' } | null) => {
					this.emptyGrowl();

					if (registerPayload) {
						if (registerPayload.action === 'success') {
							this.registrationWasSuccessful = true;
						} else {
							this.showErrorOnGrowl(this.lang[registerPayload.action]);
						}
					} else {
						this.showErrorOnGrowl('Error happened, please try again');
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore() {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
