







import { Component, Vue } from 'vue-property-decorator';
import WbHorizontalMenuContainer from '@/views/wb-app/wb-site/wb-horizontal-menu-container/wb-horizontal-menu-container.vue';

@Component({
	name : 'wb-auth',
	components : {
		WbHorizontalMenuContainer,
	},
})
export default class WbAuth extends Vue {
	public isOnline$ = true;

	/**
	 * CREATED
	 */
	public created(): void {
		this.$googleCaptcha.init('' + process.env.VUE_APP_GOOGLE_RECAPTCHA_SECRET);

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAuth ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.$googleCaptcha.remove();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAuth ', 'background: purple; color: #FFF');
	}
}
