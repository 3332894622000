




import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-router-container',
	components : {},
})
export default class WbRouteContainer extends Vue {
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbRouteContainer ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbRouteContainer ', 'background: purple; color: #FFF');
	}
}
