


























































import { Component, Vue } from 'vue-property-decorator';
import { skipWhile, tap } from 'rxjs/operators';

import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';
import WbAuthContainer from '../wb-auth-container/wb-auth-container.vue';
import { Subscription } from 'rxjs';

interface ISubscriptions {
	requestEmailForForgottenPasswordSuccess: Subscription | null;
}

@Component({
	name : 'wb-forgotten-password',
	components : {
		WbAuthContainer,
	},
})
export default class WbForgottenPassword extends Vue {
	private subscriptions: ISubscriptions = {
		requestEmailForForgottenPasswordSuccess : null,
	}

	public model: IModel = {
		fields : {
			email : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.email,
				placeholder : 'Email address',
				required : true,
				status : {},
				focus : false,
				error : {
					email : 'This is not a valid email address.',
					required : 'Email address is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public isBusy = false;

	public growl = {
		visible : false,
		status : '',
		txt : '',
	}

	private requestWasSuccessful = false;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbForgottenPassword ', 'background: blue; color: #FFF');

		this.subscribeToStore();
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbForgottenPassword ', 'background: purple; color: #FFF');

		this.unsubscribeFromStore();
	}

	public canSend(): boolean {
		return (this.model.fields.email.value !== '');
	}

	public requestEmailToResetPassword(): void {
		if (this.canSend()) {
			const formValidation = this.$formValidation;

			const validation = formValidation.validate(this.model);

			if (validation.ok()) {
				this.$googleCaptcha.getToken()
					.pipe(tap((token: string) => {
						if (token === '') {
							this.showErrorOnGrowl('Please try reset your password again.');
						} else {
							validation.result.captchaToken = token;

							// Request Reset Password Email
							this.$auth.requestEmailToResetPassword(validation.result);
							this.isBusy = true;
						}
					})).subscribe();
			}
		}
	}

	public goToLogin(): void {
		this.$router.push({ name : 'wbLogin', });
	}

	public resetMyPassword(): void {
		// eslint-disable-next-line no-console
		console.log('email sent');
	}

	private showErrorOnGrowl(txt: string) {
		this.growl.visible = true;
		this.growl.status = 'danger';
		this.growl.txt = txt;
	}

	private subscribeToStore() {
		this.$auth.resetRequestEmailForForgottenPasswordSuccess(); // !!! order

		this.subscriptions.requestEmailForForgottenPasswordSuccess = this.$auth.isRequestEmailForForgottenPasswordSuccess$
			.pipe(
				skipWhile((requestEmailPayload) => requestEmailPayload === null),
				tap((requestEmailPayload: boolean | null) => {
					if (requestEmailPayload) {
						this.requestWasSuccessful = requestEmailPayload;
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore() {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
