
























































































import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { Component, Vue } from 'vue-property-decorator';

import { IStateOfChangePassword } from '@/services/auth-store.service';
import { StatusOfChangePassword } from '@/services/api/auth-api.service';

import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';
import WbGrowl from '@/components/ui-elements/wb-growl/wb-growl.vue';
import WbAuthContainer from '../wb-auth-container/wb-auth-container.vue';
import { ResponseMessage } from '@/services/interfaces/message.interface';

interface ISubscriptions {
	changePasswordSuccess: Subscription | null;
}

@Component({
	name : 'wb-change-password',
	components : {
		WbGrowl,
		WbAuthContainer,
	},
})
export default class WbChangePassword extends Vue {
	public statusOfChangePassword = StatusOfChangePassword;
	private subscriptions: ISubscriptions = {
		changePasswordSuccess : null,
	}

	public model: IModel = {
		fields : {
			password : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.password,
				placeholder : 'Password',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Password is required.',
				},
			},
			passwordAgain : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.password,
				placeholder : 'Password',
				required : true,
				status : {},
				focus : false,
				customValidation : (val, model) => {
					if (val === model.fields.password.value) {
						return true;
					} else {
						return { txt : 'Your password and confirmation password do not match.', };
					}
				},
				error : {
					required : 'Password again is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	public growl = {
		visible : false,
		status : '',
		txt : '',
	}

	public modal = {
		status : '',
	};

	public type = '';
	private token = '';

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbChangePassword ', 'background: blue; color: #FFF');

		this.token = this.$route.params.token;
		this.type = (this.$route.meta && this.$route.meta.type) ? this.$route.meta.type : '';

		this.initConsent(this.type);

		this.subscribeToStore();
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbChangePassword ', 'background: purple; color: #FFF');

		this.unsubscribeFromStore();
	}

	public goToLogin(): void {
		this.$router.push({ name : 'wbLogin', });
	}

	public canSend(): boolean {
		return (
			(
				this.model.fields.acceptTermsAndConditions !== undefined &&
				this.model.fields.acceptTermsAndConditions.value === true &&
				this.model.fields.password.value !== '' &&
				this.model.fields.passwordAgain.value !== ''
			) ||
			(
				this.model.fields.acceptTermsAndConditions === undefined &&
				this.model.fields.password.value !== '' &&
				this.model.fields.passwordAgain.value !== ''
			)
		);
	}

	public changePassword(): void {
		if (this.canSend()) {
			const formValidation = this.$formValidation;
			const validation = formValidation.validate(this.model);

			if (validation.ok()) {
				// Get token
				this.$googleCaptcha.getToken()
					.pipe(tap((captchaToken: string) => {
						if (captchaToken === '') {
							this.showErrorOnGrowl('Please try change password process again.');
						} else {
							validation.result.captchaToken = captchaToken;

							// Change Password
							this.$auth.changePassword(validation.result, this.token);
							this.isBusy = true;
						}
					})).subscribe();
			}
		}
	}

	private initConsent(type: string) {
		if (type === 'addUser') {
			this.$set(this.model.fields, 'acceptTermsAndConditions', {
				oldValue : false,
				value : false,
				placeholder : 'Terms and Conditions',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'You need to accept the Terms and Conditions',
				},
			});
		}
	}

	private showErrorOnGrowl(txt: string) {
		this.growl.visible = true;
		this.growl.status = 'danger';
		this.growl.txt = txt;
	}

	private emptyErrorOnGrowl() {
		this.growl.visible = false;
		this.growl.status = '';
		this.growl.txt = '';
	}

	private subscribeToStore() {
		this.subscriptions.changePasswordSuccess = this.$auth.stateOfChangePassword$
			.pipe(
				skipWhile((stateOfChangePassword) => stateOfChangePassword === null),
				tap((stateOfChangePassword: IStateOfChangePassword | null) => {
					const code: keyof Pick<ResponseMessage, 'password_too_short' | 'password_weak'> | null =
						(stateOfChangePassword)
							? stateOfChangePassword.status as ('password_too_short' | 'password_weak')
							: null;

					if (code) {
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						this.modal.status = code; // Can't be null because of skipWhile

						if (this.modal.status === StatusOfChangePassword.passwordTooShort) {
							this.showErrorOnGrowl(this.$auth.lang[code]);
						} else if (this.modal.status === StatusOfChangePassword.passwordWeak) {
							this.showErrorOnGrowl(this.$auth.lang[code]);
						} else if (this.modal.status === StatusOfChangePassword.success) {
							this.emptyErrorOnGrowl();
						}
					} else {

					}
					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore() {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
