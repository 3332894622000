













import { Subscription } from 'rxjs';
import { Component, Vue } from 'vue-property-decorator';

import { IToast } from '../../../services/common/toaster-store.service';

@Component({
	name : 'wb-toaster',
	components : {},
})
export default class WbToaster extends Vue {
	public toasts: IToast[] = [];

	private subscriptionToStore: Subscription | null = null;
	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToService();

		// eslint-disable-next-line no-console
		console.log('%c CREATE WbToaster ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromService();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbToaster ', 'background: purple; color: #FFF');
	}

	public onRemove(toast: IToast): void {
		this.$toaster.remove(toast, true);
	}

	private subscribeToService(): void {
		this.subscriptionToStore = this.$toaster.toasts$.subscribe((toasts) => {
			this.toasts = toasts;
		});
	}

	private unsubscribeFromService(): void {
		if (this.subscriptionToStore) {
			this.subscriptionToStore.unsubscribe();
		}
	}
}
