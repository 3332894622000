
















import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-auth-container',
	components : {
	},
})
export default class WbAuthContainer extends Vue {
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAuthContainer ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c WbAuthContainer ', 'background: purple; color: #FFF');
	}
}
