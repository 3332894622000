














































import { Component, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';

import { IUser } from '@/services/payloads/user.payload';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	listOneUser: Subscription | null;
	topUpBalance: Subscription | null;
}

@Component({
	name : 'wb-request-top-up-modal',
	components : {
	},
})
export default class WbRequestTopUpModal extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	public upToDateUserData: IUser | null = null;

	private subscriptions: ISubscriptions = {
		listOneUser : null,
		topUpBalance : null,
	};

	public model: IModel = {
		fields : {
			amount : {
				oldValue : 0,
				value : 0,
				type : InputTypeTypes.int,
				placeholder : 'Amount',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Amount is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		this.resetStateAndSubscribeToStore();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbRequestTopUpModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbRequestTopUpModal ', 'background: purple; color: #FFF');
	}

	public save(): void {
		if (this.model.properties.dirtyFields.length) {
			const validation = this.$formValidation.validate(this.model);

			if (validation.ok()) {
				this.$services.balance.requestTopUp(validation.result);
				this.isBusy = true;
			}
		}
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.balance.resetRequestResult(); // !!! oder

		this.subscriptions.topUpBalance = this.$services.balance.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'requestTopUp') {
							this.$nextTick(() => {
								this.$emit('close-modal');
							});
						}
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
