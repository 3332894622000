








































































































































































import { Component, Vue } from 'vue-property-decorator';

import { Subject, Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';

import { IListOneUserPayload, IUserActivity, UserLogTypes } from '@/services/payloads/user.payload';
import { IPaymentHistory } from '@/services/api/balance-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

import WbChangePasswordModal from './wb-change-password-modal/wb-change-password-modal.vue';
import WbRequestTopUpModal from './wb-request-top-up-modal/wb-request-top-up-modal.vue';
import WbPaymentHistory from '../_components/wb-payment-history/wb-payment-history.vue';

interface IFilteredPaymentHistory {
	event: string;
	amount: number;
	message: string;
	message2: string;
	time: string;
	color: string;
}

interface ISubscriptions {
	ownData: Subscription | null;
	paymentHistories: Subscription | null;
}

@Component({
	name : 'wb-user-profile',
	components : {
		WbRequestTopUpModal,
		WbChangePasswordModal,
		WbPaymentHistory,
	},
})

export default class WbUserProfile extends Vue {
	private subscriptions: ISubscriptions = {
		ownData : null,
		paymentHistories : null,
	};

	public model: IModel = {
		fields : {
			organization : {
				oldValue : '',
				value : '',
				placeholder : 'Company Name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Company name is required.',
				},
			},
			firstname : {
				oldValue : '',
				value : '',
				placeholder : 'First name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'First name is required.',
				},
			},
			lastname : {
				oldValue : '',
				value : '',
				placeholder : 'Last name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Last name is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public staticData: IUserActivity = {
		id : 0,
		balance : 0,
		lastLoginDate : new Date(),
		lastTopUpRequest : new Date(),
		numberOfLogins : 0,
		lastActiveDate : new Date(),
		allActiveTimeInMinutes : 0,
		lastUsedFlasherVersion : '',
		numberOfConfigDownloads : 0,
		numberOfConfigLoads : 0,
		numberOfConfigSaves : 0,
		numberOfConfigUploads : 0,
		numberOfConfigUploadsToDevice : 0,
		numberOfFirmwaresBurnt : 0,
	};

	public modelMisc: IModel = {
		fields : {
			email : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.email,
				placeholder : 'Email address',
				required : false,
				status : {},
				focus : false,
				disabled : true,
				error : {
					required : 'Email address is required.',
					email : 'This is not an email address',
				},
				readonly : true,
			},
			role : {
				oldValue : '',
				value : '',
				placeholder : 'Role',
				required : true,
				status : {},
				disabled : true,
				focus : false,
				error : {
					required : 'Role is required',
				},
				readonly : true,
			},
			isDeveloper : {
				oldValue : false,
				value : false,
				placeholder : 'Developer',
				required : false,
				status : {},
				focus : false,
				error : {
					required : 'XY is required.',
				},
				readonly : true,
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public modal = {
		changeOwnPassword : false,
		requestTopUp : false,
	};

	private isBusy = false;
	private restart = new Subject<boolean>();
	public restart$ = this.restart.asObservable();

	public ownData = {};

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 50,
	};

	public paymentHistories: IPaymentHistory[] = [];

	get filteredPaymentHistories(): IFilteredPaymentHistory[] {
		const log: IFilteredPaymentHistory[] = [];
		let event: string;
		let message: string;
		let message2: string;
		let time: string;
		let oldBalance: number;
		let newBalance: number;
		let color: string;

		this.paymentHistories.forEach((paymentHistory) => {
			event = '';
			message = '';
			message2 = '';
			time = this.$region.formatTime(paymentHistory.createdAt);
			oldBalance = 0;
			newBalance = 0;
			color = '';

			if (paymentHistory.type === UserLogTypes.balanceChanged) {
				if (paymentHistory.details && paymentHistory.details.firmwareName) {
					event = 'Firmware burnt';
					color = 'danger';
				} else if (paymentHistory.amount > 0) {
					event = 'Top up';
					color = 'success';
				} else {
					event = 'Withdraw';
					color = 'danger';
				}

				oldBalance = (paymentHistory.details && paymentHistory.details.oldBalance) ? +paymentHistory.details.oldBalance : -1;
				newBalance = (paymentHistory.details && paymentHistory.details.newBalance) ? +paymentHistory.details.newBalance : -1;

				if (oldBalance > 0 && newBalance > 0) {
					message = `
						Your balance has changed from ${oldBalance} to ${newBalance}
					`;
				} else {
					message = 'Unknown balance';
				}

				if (paymentHistory.details && paymentHistory.details.firmwareName) {
					message2 = `Firmware was burnt ${paymentHistory.details.firmwareName} ${paymentHistory.details.firmwareVersion}`;
				}

				log.push({ event, amount : paymentHistory.amount, message, message2, time, color, });
			} else if (paymentHistory.type === UserLogTypes.balanceTopUpRequested) {
				event = 'Request Credit';
				message = 'Request credit';
				color = 'info';
				log.push({ event, amount : paymentHistory.amount, message, message2, time, color, });
			}
		});

		return log;
	}

	/**
     * CREATED
     */
	public created(): void {
		this.resetStateAndSubscribeToStore();
		this.$services.user.getProfile();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbUserProfile ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbUserProfile ', 'background: purple; color: #FFF');
	}

	public canSend(): boolean {
		return this.model.properties.dirtyFields.length > 0;
	}

	public update(): void {
		if (this.canSend()) {
			const formValidation = this.$formValidation;

			const validation = formValidation.validate(this.model);

			if (validation.ok()) {
				this.$services.user.modifyProfile(validation.modifiedCurrResult);
				this.isBusy = true;
			}
		}
	}

	public openModal(): void {
		this.modal.changeOwnPassword = true;
	}

	public closeModal(): void {
		this.modal.changeOwnPassword = false;
	}

	public openRequestTopUpModal(): void {
		this.modal.requestTopUp = true;
	}

	public closeRequestTopUpModal(): void {
		this.modal.requestTopUp = false;
		this.restart.next(true);
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.user.resetRequestResult(); // !!! oder

		this.subscriptions.ownData = this.$services.user.profile$
			.pipe(
				tap((ownData: IListOneUserPayload | null) => {
					if (ownData) {
						const loginFields = this.model.fields;
						loginFields.organization.value = loginFields.organization.oldValue = ownData.organization;
						loginFields.firstname.value = loginFields.firstname.oldValue = ownData.firstname;
						loginFields.lastname.value = loginFields.lastname.oldValue = ownData.lastname;

						const miscFields = this.modelMisc.fields;
						miscFields.email.value = miscFields.email.oldValue = ownData.email;

						if (miscFields.role && ownData.role) {
							let roleTxt = this.$auth.lngUserRoles[ownData.role];
							if (ownData.privilege.developer) {
								roleTxt += ' with developer right';
							}

							miscFields.role.value = miscFields.role.oldValue = roleTxt;
						}

						this.staticData.balance = ownData.activity.balance;
						this.staticData.lastLoginDate = ownData.activity.lastLoginDate;
						this.staticData.lastTopUpRequest = ownData.activity.lastTopUpRequest;
						this.staticData.numberOfLogins = ownData.activity.numberOfLogins;
					}
				})
			).subscribe();

		this.subscriptions.ownData = this.$services.user.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {}
					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
