




























































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';

import { IUser } from '@/services/payloads/user.payload';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	changeOwnPassword: Subscription | null;
}

@Component({
	name : 'wb-change-password-modal',
	components : {
	},
})
export default class WbChangePasswordModal extends Vue {
	@Prop({ default : {}, })
	public data!: IUser;

	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		changeOwnPassword : null,
	}

	public model: IModel = {
		fields : {
			newPassword : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.password,
				placeholder : 'New Password',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'New Password is required.',
				},
			},
			newPasswordAgain : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.password,
				placeholder : 'New Password Again',
				required : true,
				status : {},
				focus : false,
				customValidation : (val, model) => {
					if (val === model.fields.newPassword.value) {
						return true;
					} else {
						return { txt : 'Your password and confirmation password do not match.', };
					}
				},
				error : {
					required : 'New Password again is required.',
				},
			},
			oldPassword : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.password,
				placeholder : 'Current Password',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Current Password is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbChangePasswordModal ', 'background: blue; color: #FFF');

		this.subscribeToStore();
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbChangePasswordModal ', 'background: purple; color: #FFF');

		this.unsubscribeFromStore();
	}

	public changePassword(): void {
		const validation = this.$formValidation.validate(this.model);

		if (validation.ok()) {
			// Delete
			validation.result.id = this.data.id;
			this.$services.user.changeOwnPassword(validation.result);
			this.isBusy = true;
		}
	}

	private subscribeToStore() {
		this.subscriptions.changeOwnPassword = this.$services.user.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'changeOwnPassword') {
							this.$emit('close-modal');
						}
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore() {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
