



















import { Component, Vue } from 'vue-property-decorator';

import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';

import { UserRoles } from '@/services/payloads/user.payload';

import wbToaster from '@/components/ui-elements/wb-toaster/wb-toaster.vue';
import wbHorizontalMenuContainer from '@/views/wb-app/wb-site/wb-horizontal-menu-container/wb-horizontal-menu-container.vue';
import WbLogoutModal from './wb-horizontal-menu-container/wb-logout-modal/wb-logout-modal.vue';

@Component({
	name : 'wb-site',
	components : {
		WbLogoutModal,
		wbHorizontalMenuContainer,
		wbToaster,
	},
})
export default class WbSite extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	public isLogoutModalOpen = false;

	/**
	 * CREATED
	 */
	public async created(): Promise<void> {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbSite ', 'background: blue; color: #FFF');

		const userRole: UserRoles = await this.$auth.getUserRole();

		if (this.$route && this.$route.name !== 'wbSite') {
			this.$router.push({ name : String(this.$route.name), });
		} else {
			await this.$menu.reload();
			switch (userRole) {
				case UserRoles.SUPERADMIN:
				case UserRoles.ADMIN:
					this.$router.push({ name : 'adminHome', });
					break;
				case UserRoles.USER:
					this.$router.push({ name : 'userHome', });
					break;
				default:
					this.$router.push({ name : 'wbLogin', });
					break;
			}
		}
	}

	public mounted(): void {
		document.documentElement.classList.add('html-visible');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbSite ', 'background: purple; color: #FFF');
	}
}
