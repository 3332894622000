import { render, staticRenderFns } from "./wb-confirm-email-address.vue?vue&type=template&id=25c8ec2e&scoped=true&"
import script from "./wb-confirm-email-address.vue?vue&type=script&lang=ts&"
export * from "./wb-confirm-email-address.vue?vue&type=script&lang=ts&"
import style0 from "./wb-confirm-email-address.vue?vue&type=style&index=0&id=25c8ec2e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c8ec2e",
  null
  
)

export default component.exports